$(
	 function() {
		 app.assignNumberControl();
		 app.setSelectedLink();
		 app.addRegisterCheckBoxes();
		 app.attachSvgEvents();
		 app.makeSocialButtons();
		 app.setBackUrls();
		 app.addCoverSpan();
		 app.saveProductListInfo();
         app.correctTopMenuPopups();
		 app.assignProductOver();
		 app.initializeScrollButton();
         app.hamburger.init();

         var $transTable = $('table.transactions');
         if ($transTable.find('td.tools').length > 0) {
             $transTable.find('td.empty').removeClass('empty');
         }

         $('.quantity input').change(
         	function() {
         		var that = this;
         		app.blockWindow(
         			true,
					true,
					function () {
                        $(that).closest('form').submit();
                    }
				)
			}
		 );

		 $('li.li-mob-button a').click(
		 	function() {
		 		$('.top-hamburger').toggleClass('opened');
			}
		 );

         var $lnk = $('.checkout-cart');
         if ($lnk.length > 0) {
             $.get($lnk.attr('href'))
		 }

		 var $items = $('.shipping-methods input.ship-input');
         if ($items.length > 0) {
             $items.change(
             	function() {
             		var $this = $(this);
             		var id = $this.attr('id');
             		app.setLData('lastShippingMethodSelected', id);
				}
			 );
			 var lastMethod = app.getLData('lastShippingMethodSelected');
			 if (lastMethod) {
			 	$('#' + lastMethod).prop('checked', true);
			 }
		 }
	 }
);


	var app = {
        _VERSION: 2,
        prodItemCorrection: -30,
		indexLinkPos : 0,
		messages : {},
		productAvailableQuantityUrl : null,
		optionsWereInitialized : false,
        responsiveMode: true,
		showSearch : function() {
			if ($('.li-search .ul-mega-1').hasClass('hidden')) {
				$('.li-search').addClass('hover');
				$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
			} else {
				$('.li-search').removeClass('hover');
				$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
			}
		},
		doBeforePriceSliderShown: function(trg) {
			var color = app.getClassColor('schema-color');
			$('.noUi-connect').css('background-color', color);
		},
        initSurvey: function(url, quest, surveyURL) {
        	var $links = $("a.button.checkout-cart");
			if (app.getLData('_surveyIsDone') !== 'Y') {
				$links.click(
					function() {
						app.askAboutSurvey(url, quest, surveyURL);
					}
				);
			} else {
                $links.attr('href', url);
			}
		},
		askAboutSurvey: function (url, surveyURL) {
            app.confirmMsg(
                '_Survey_Question',
                function() {
                    setTimeout(
                        function() {
                            app.setLData('_surveyIsDone', 'Y');
                            var dialogParams = {
                                okTitle: 'Close',
                                cssClass: 'fullMode wideWnd',
                                appendToSelector: '._'
                            };
                            app.dialog(
                                '<div class="surveyWnd height-correctable">' +
                                '<iframe frameborder="no" src="' + surveyURL + '">' +
                                '</div>',
                                function() {
                                	if (url !== '') {
                                        window.location = url;
									}

                                },
                                dialogParams
                            );

                            $('#udu-div-window').find('button.close').click(
                                function() {
                                	if (url !== '') {
                                        window.location = url;
									}
                                }
                            );
                        },
                        700
                    )
                },
                function() {
                    app.setLData('_surveyIsDone', 'Y');
                    window.location = url;
                }
            );
        },
        wrapMultiInputs: function() {
            $('.mult-quantity input.number.text, .div-cart-table .tbl-bordered td input[name=quantity]')
                .wrap("<div class='mult-input-wrp div-dummy'></div>");
            $('.mult-input-wrp')
                .prepend('<button class="custom-button btn-minus btn-quant" type="button">-</button>')
                .append('<button class="custom-button btn-plus btn-quant" type="button">+</button>');
        },
        runIndexCarusel: function() {
        	if ($('#index-slider-ini').find('a').length === 0 && !window.admin) {
        		$('#bottomSlider').remove();
			}
            app.runCarusel({
                innerHTML: '<div class="zoomer"><div class="inner"></div></div>'
            });
            $('.jcarousel-list li').each(
                function() {
                    var href = $(this).find('.image a').attr('href');
                    $(this).find('.zoomer').click(
                        function() {
                            document.location = href;
                        }
                    )
                }
            )
        },
        doBudgetSearch: function(item) {
        	var val = $(item).val();
        	app.setLData('lastBudgetSearch', val);
			val = val.toLowerCase();
			var cnt = 0;
			$('table.budgets-list tr, .budgetId table.tbl-bordered tr').each(
				function() {
					var $this = $(this);
					if (val) {
						var needShow = false;
						var $tds = $this.find('td.description, td.costCenter');
						if ($tds.length === 0) {
							return true;
						}
						$tds.each(
							function() {
								if ($(this).html().toLowerCase().indexOf(val) >= 0) {
									needShow = true;
									return false;
								}
							}
						);
					} else {
						needShow = true;
					}

					if (needShow) {
						$this.removeClass('hidden');
						cnt++;
					} else {
						$this.addClass('hidden');
					}
				}
			);
			var $noData = $('.no-data');
			if (cnt === 0) {
				$noData.removeClass('hidden');
			} else {
				$noData.addClass('hidden');
			}
		}
	};